import { Button } from "react-bootstrap";

let siteContent = {
  aboutUs:
    "At Make My Meal Meatless we are convinced that eating vegetarian and vegan foods doesn’t need to be a compromise. We believe you can change what you eat without changing what you are used to. Our mission is to help people create the classic recipes they love whilst saving animals lives, reducing the impact on the planet and improving health. The aim of MMMM is not to create substitutes, it’s about making delicious food.",
  howToUse: (
    <>
      Have one of your favourite meat meals that you want to convert to being
      vegetarian? Search for the meat used in the dish within the search bar,
      click on the closet relating option in the drop down and press ‘find an
      alternative’. You will then be shown all the substitutes available from
      our retailers on the next screen. You can sort and filter the results by
      numerous options and if there are lots of substitutes, don’t forget to
      look on the other pages by using the arrows at the bottom of the screen.
      Once you find an alternative that takes your fancy, click on the retailers
      logo to be redirected to the product on their site where you can buy it
      directly!
      <br />
      <br />
      For example, my mum makes a banging shepherds pie but I want a veggie
      version. I type in lamb mince in the search bar, click on the first
      option, then press ‘find an alternative’. I really like meatless farm and
      do my weekly shopping on Ocado so I click on their logo and enjoy my
      delicious vegetarian shepherds pie!
    </>
  ),
  faqs: [
    {
      title: "What are the benefits of meatless meals?",
      text: "You’ll be healthier! People who eat less meat typically eat fewer calories and less fat, weigh lighter, and have a lower risk of heart disease. The planet will thank you! Reducing your meat consumption can help contain the production of greenhouse gases that impact climate change. Eating less meat also lessens the demand for our earths precious resources such as land, water, and energy. For example, livestock production uses 75% of the earth’s agricultural land. To find out more check out: www.mondaycampaigns.org/meatless-monday/benefits",
    },
    {
      title: "How do I cook the substitutes?",
      text: "We always recommend checking the cooking instructions on the back of your substitutes.",
    },
    {
      title: "How do I store the substitutes?",
      text: "As a general rule, treat most subsitutes like you would normal meat, but we always recommend checking the storage instructions on your substitutes to make sure.  Don’t forget on our site we display fresh, frozen and cupboard food items so double check on the re-directed retailers site if your unsure which your substitute is!",
    },
    {
      title: "Will the site change?",
      text: "We hope for the better! We have lots of exciting ideas to develop the site further and will hopefully implement them in the future. Our biggest hope is to bring more retailers on board so more people can benefit from our tool! If you have any ideas of features, please do get in touch! Check out our ‘Contact us’ page to find out how!",
    },
    {
      title: "Are you independent?",
      text: "We are completely independent! We are a small team working on our dream to help people eat more veggie/vegan food and everything we display on the site has been painstakingly chosen by us to ensure it fits in our mission.",
    },
    {
      title: "How do I send my feedback?",

      text: (
        <>
          We welcome all forms of feedback. This is the only way we are going to
          grow and develop! You can send feedback via the form button below,
          message us on Instagram{" "}
          <a
            href="https://instagram.com/makemymealmeatless?utm_medium=copy_link
https://instagram.com/makemymealmeatless">
            @makemymealmeatless
          </a>{" "}
          or email us at{" "}
          <a href="mailto: makemymealmeatless@gmail.com">
            {" "}
            makemymealmeatless@gmail.com
          </a>
          . We’d love to hear from you!
          <br />
          <br />
          <div className="buttonFlexCenter">
            <Button
              target="blank"
              variant="warning"
              className="goButton accentButton"
              href="https://forms.gle/28Kp2gatVLNNsKvK9">
              Send Feedback <i class="bi bi-pencil"></i>
            </Button>
          </div>
        </>
      ),
    },
    {
      title: "Do you have an app?",
      text: "We do not currently have an app but we really hope to in the future! You can save our site within your browser or add it to your home-screen through your phone/tablets functionality. This will act just like an app!",
    },
    {
      title: "Is this tool free?",
      text: "The tool is completely free for anyone and everyone to use! We believe the best way to have an impact is by allowing everyone free access to information.",
    },
    {
      title: "How do you make revenue?",
      text: "We sometimes make a small amount when you purchase through the links on our site. This doesn’t increase the price you pay, and helps support the site a great deal! We also make money through the ads on our site. We try and keep these unobtrusive so that users like you can easily find delicious meat free alternatives!",
    },
    {
      title: "Can I collaborate with you?",
      text: "Power in numbers is a remarkable thing. We welcome any ideas and suggestions for collaboration as long as you share the common goal of loving delicious food that is meat free. Get in contact - check out our ‘Contact us’ page to find out how! We’d love to hear from you!",
    },
    {
      title: "I’m a retailer, how do I get listed?",
      text: "Hi! We would love to have you on board as long as you sell some vegetarian or vegan items. If this is you, get in contact, check out our ‘Contact us’ page to find out how!",
    },
    {
      title: "Any other questions?",
      text: "Get in contact, check out our ‘Contact us’ page to find out how!",
    },
  ],
  contactUs: (
    <>
      Want to get in contact? Message us on Instagram{" "}
      <a
        href="https://instagram.com/makemymealmeatless?utm_medium=copy_link
https://instagram.com/makemymealmeatless">
        @makemymealmeatless
      </a>{" "}
      or email us at{" "}
      <a href="mailto: makemymealmeatless@gmail.com">
        {" "}
        makemymealmeatless@gmail.com
      </a>
      . Provide feedback or suggestions for our site at the button below. We’d
      love to hear from you!
    </>
  ),
  supportUs: (
    <>
      We don’t charge anyone for using our tool but if you like what we do and
      want to support us, you can{" "}
      <a target="_blank" href="https://www.buymeacoffee.com/MeatlessMeal">
        Buy us a Coffee
      </a>
      . We are a small team working on our mission and are extremely grateful
      for any contributions. Equally, if you’re not feeling flush, follow us on{" "}
      <a
        target="_blank"
        href="https://www.instagram.com/makemymealmeatless/?hl=en-gb">
        Instagram
      </a>
      . Here we share recipes using the products from the site, additional info
      about going meatless, and much much more! You can also use
      #makemymealmeatless to share your experiences of using the site and any
      delicious food you’ve made from it.
      <br />
      <br />
      If you’ve really enjoyed using our tool and think someone you know could
      benefit from it, please let them know. We are a small but growing brand
      aiming to help as many people as possible to make simple swaps that save
      animals lives, the planet and their health!
    </>
  ),
  disclaimer:
    "Here at Make My Meal Meatless we try our best to provide accurate information to our users. However, sometimes we may make mistakes; we use modern technologies to gather, analyse and process product data and occasionally these processes can go wrong. For example, if the product data provided to us labels a product as vegan when it is only vegetarian, this may mean that the product is displayed to you, the website user, as vegan. Therefore, when buying a product that we promote on the site as vegan or vegetarian, always check that it meets your personal lifestyle, dietary and allergen requirements. Make My Meal Meatless accepts no liability for any incorrect labelling of products on the site.",
};

export default siteContent;

{
  /* <>
<ul>
  <li>
    <b>What are the benefits of meatless meals?</b>
    <br />
    You’ll be healthier! People who eat less meat typically eat fewer
    calories and less fat, weigh lighter, and have a lower risk of heart
    disease. The planet will thank you! Reducing your meat consumption can
    help contain the production of greenhouse gases that impact climate
    change. Eating less meat also lessens the demand for our earths
    precious resources such as land, water, and energy. For example,
    livestock production uses 75% of the earth’s agricultural land. To
    find out more check out:
    www.mondaycampaigns.org/meatless-monday/benefits
  </li>
  <li>
    <b>How do I cook the substitutes?</b>
    <br />
    We always recommend checking the cooking instructions on the
    back of your substitutes.
  </li>
  <li>
    <b>How do I store the substitutes?</b>
    <br /> As a general rule, treat most subsitutes like you would normal meat, but we always recommend checking the storage instructions on your substitutes to make sure. 
    Don’t forget on our site we display fresh, frozen and cupboard food
    items so double check on the re-directed retailers site if your unsure
    which your substitute is!
  </li>
  <li>
    <b>Will the site change?</b>
    <br /> We hope for the better! We have lots of exciting ideas to
    develop the site further and will hopefully implement them in the
    future. Our biggest hope is to bring more retailers on board so more
    people can benefit from our tool! If you have any ideas of features,
    please do get in touch! Check out our ‘Contact us’ page to find out
    how!
  </li>
  <li>
    <b>Are you independent?</b>
    <br /> We are completely independent! We are a small team working on
    our dream to help people eat more veggie/vegan food and everything we
    display on the site has been painstakingly chosen by us to ensure it
    fits in our mission.
  </li>
  <li>
    <b>How do I get in touch?</b>
    <br /> You can message us on Instagram (@makemymealmeatless) or email
    us at makemymealmeatless.com. We’d love to hear from you!
  </li>
  <li>
    <b>How do I send my feedback?</b>
    <br /> We welcome all forms of feedback. This is the only way we are
    going to grow and develop! You can message us on Instagram
    (@makemymealmeatless) or email us at makemymealmeatless.com. We’d love
    to hear from you!
  </li>
  <li>
    <b>Do you have an app?</b>
    <br /> Sadly we do not currently have an app but we really hope to in
    the future! You can save our site within your browser or add it to
    your home-screen through your phone/tablets functionality. This will
    act just like an app!
  </li>
  <li>
    <b>Is this tool free?</b>
    <br />The tool is completely free for anyone and everyone to use! We
    believe the best way to have an impact is by allowing everyone free
    access to information.
  </li>
  <li>
    <b>How do you make revenue?</b>
    <br />We make sometimes make a very small amount when you purchase
    through the links on our site. This doesn’t increase the price you
    pay, and helps support the site a great deal! We also make money
    through the ads on our site. We try and keep these unobtrusive so that
    users like can easily find delicious meat free alternatives!
  </li>
  <li>
    <b>Can I collaborate with you?</b>
    <br />Power in numbers is a remarkable thing. We welcome any ideas
    and suggestions for collaboration as long as you share the common goal
    of loving delicious food that is meat free. Get in contact - check out
    our ‘Contact us’ page to find out how! We’d love to hear from you!
  </li>
  <li>
    <b>I’m a retailer, how do I get listed?</b>
    <br />Hi! We would love to have you on board as long as you sell some
    vegetarian or vegan items. If this is you, get in contact, check out
    our ‘Contact us’ page to find out how!
  </li>
  <li>
    <b>Any other questions?</b>
    <br />Get in contact, check out our ‘Contact us’ page to find out
    how!
  </li>
</ul>
</> */
}
