import { v4 as uuidv4 } from "uuid";
import { Dropdown, Form } from "react-bootstrap";
import InputForm from "../InputForm";

export default function RenderOptions(props) {
  const {
    options,
    results_filters,
    forceUpdate,
    handleOption,
    substitutes,
    results,
    setResults,
    setInputs,
    inputs,
    searchInput,
    setSearchInput,
    pickedMeat,
    setPickedMeat,
  } = props;

  const renderPage = "Results";

  const data_for_input_form = {
    results,
    setResults,
    setInputs,
    inputs,
    searchInput,
    setSearchInput,
    pickedMeat,
    setPickedMeat,
    renderPage,
  };

  let renderedOptions = [];

  options.forEach((el) => {
    renderedOptions.push(
      <Dropdown
        id="dropdown-basic-button"
        title="Dropdown button"
        key={uuidv4()}>
        <Dropdown.Toggle
          variant="warning"
          id="dropdown-basic"
          className="resultsFilter grey-button-border">
          {el.name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.keys(el.options).map((option) => (
            <Dropdown.Item
              className={`${
                results_filters[el.key].includes(el.options[option].key)
                  ? "dropdownItemActive"
                  : "dropdownItem"
              }`}
              onClick={() => {
                forceUpdate();
                handleOption(el, el.options[option], substitutes);
              }}
              key={uuidv4()}>
              {`${el.options[option].name}${
                el.key === "sort" ? " " : ` (${el.options[option].no}) `
              }${
                results_filters[el.key].includes(el.options[option].key)
                  ? "✓"
                  : ""
              }`}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  });

  return (
    <div className="resultsOptionsContainer">
      {/* <Form>
        <Form.Group className="" controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value="Beef Burger"
          />
        </Form.Group>
      </Form> */}
      <InputForm {...data_for_input_form} />
      {renderedOptions}
    </div>
  );
}
