export default function RenderFilters({
  results,
  setResults,
  forceUpdate,
  handleOption,
  wipe_filters,
  substitutes,
  options,
}) {
  let filtersRendered = [];
  Object.keys(results.resultsFilters).forEach((resultsTypeKey) => {
    results.resultsFilters[resultsTypeKey].forEach((option) => {
      let el = options.find((value) => value.key === resultsTypeKey);
      let option_object = el.options.find((item) => item.key === option);
      console.log(el);
      filtersRendered.push(
        <div
          className="resultsFilterRendered"
          onClick={() => {
            forceUpdate();
            handleOption(el, option_object, substitutes);
          }}>
          {option}
          <i
            className="bi bi-x"
            style={{
              fontSize: "0.8rem",
              baselineShift: "10px",
            }}></i>
        </div>
      );
    });
  });

  if (filtersRendered.length > 0) {
    filtersRendered.push(
      <div
        className="resultsFilterRenderedClearAll"
        onClick={() => {
          forceUpdate();
          wipe_filters(results, setResults);
        }}>
        Clear All Filters{" "}
        <i
          className="bi bi-x-square-fill"
          style={{
            fontSize: "0.8rem",
            baselineShift: "10px",
          }}></i>
      </div>
    );
  }

  return <div className="resultsFiltersContainer">{filtersRendered}</div>;
}
