import React from "react";
import ErrorBoundary from "../Components/errorBoundary";
import InputForm from "../Components/InputForm";

// Mainpage returns the elements on the main page of the website such as the title and input form
export default function MainPage(props) {
  const {
    results,
    setResults,
    inputs,
    setInputs,
    searchInput,
    setSearchInput,
    pickedMeat,
    setPickedMeat,
  } = props;

  const renderPage = "Main page";

  return (
    <ErrorBoundary>
      <div className="mainpageContentContainer">
        <div className="heroContainer">
          {" "}
          <div className="heroText">
            <span className="heroTextTopLine">Delicious.</span>
            <br />
            <span className="heroTextBottomLine">Meat-free.</span>
            <br />
            <div className="heroTextSubtitle">
              Convert meat-based ingredients into meat-free alternatives, here
              at{" "}
              <a style={{ fontWeight: "500", cursor: "pointer" }}>
                Make My Meal Meatless.
              </a>
            </div>
          </div>
          <img className="heroImageContainer" src="Product Images.png" />
        </div>
        <div className="searchContainer">
          <div className="searchPanelBox">
            <span className="searchPanelLargeText">
              What will you find today?
            </span>
            <InputForm {...props} renderPage={renderPage} />
          </div>
        </div>
      </div>{" "}
    </ErrorBoundary>
  );
}
