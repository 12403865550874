import React from "react";
import InputForm from "../Components/InputForm";
import Disclaimer from "../Components/Disclaimer";
import LearnMore from "../Components/LearnMore";
import ErrorBoundary from "../Components/errorBoundary";

// Mainpage returns the elements on the main page of the website such as the title and input form
export default function InputPage(props) {
  return (
    <ErrorBoundary>
      <div className="contentContainer">
        <div className="backgroundContentContainer">
          <h1 className="titleText leftText">Convert My Meal</h1>
          <br />
          <div className="advertHandler">
            <div className="nonAdvertContent">
              <InputForm {...props} />
              <br />
            </div>
            <div className="advertBox">
              {/* <amp-ad
                width="100vw"
                height="320"
                type="adsense"
                data-ad-client="ca-pub-9797460845647267"
                data-ad-slot="2034049746"
                data-auto-format="rspv"
                data-full-width="">
                <div overflow=""></div>
              </amp-ad> */}
            </div>
          </div>
        </div>
        <LearnMore />
        <Disclaimer />
      </div>
    </ErrorBoundary>
  );
}
