import React, { useState, useRef, useEffect } from "react";
import { Form, FloatingLabel, Card, ListGroup } from "react-bootstrap";
import allData from "./Data/database";
require("string_score");

export default function MeatSearch({
  searchInput,
  setSearchInput,
  pickedMeat,
  setPickedMeat,
  inputs,
  setInputs,
  validation,
  setValidation,
  handleSubmit,
  setResults,
  renderPage,
}) {
  let potentialResults = [];
  let searchResults = [];
  let scoredResults = [];
  let sortedResults = [];
  let processedResults = [];

  // TODO: Make popularResults dynamic
  let popularResults = [
    "Chicken Pieces",
    "Chicken",
    "Bacon",
    "Fish",
    "Hot Dog",
    "Beef Burger",
  ];

  const [boxFocus, setboxFocus] = useState(false);

  // Create a ref to the element you want to scroll to
  const myElementRef = useRef(null);

  const handleScroll = () => {
    if (myElementRef.current && window.innerWidth > 768) {
      const elementTop = myElementRef.current.getBoundingClientRect().top;
      const scrollTop = document.documentElement.scrollTop;
      const destination = elementTop + scrollTop - 100;
      window.scrollTo({ top: destination, behavior: "smooth" });
    }
  };

  // BELOW: ALL FUNCTIONS

  const handleSearchInput = (e) => {
    e.preventDefault();
    console.log("Step 11", e.target.value);
    setSearchInput(e.target.value);
    setInputs({ ...inputs, meatType: { key: "", name: "" } });
    setPickedMeat("");
    setValidation(true);
  };

  const pickTopSearchItem = (e) => {
    console.log("Enter key has been pressed");
    try {
      handlePickedMeat(e, sortedResults[0]);
    } catch (e) {}
  };

  const pickAllResults = (e) => {
    handlePickedMeat(e, "all");
  };

  // When the user picks a meat from the list, we set the picked meat, set the search input and change the inputs, and the validity of the item is now true.
  const handlePickedMeat = (e, sortedResult) => {
    e.preventDefault();
    setboxFocus(false);
    console.log("handlePickedMeat");
    if (sortedResult === "all") {
      handleSubmit(
        setResults,
        {
          ...inputs,
          meatType: {
            key: "all",
            name: "all",
          },
        },
        e
      );
    } else {
      console.log("Step 12", e);
      setPickedMeat(sortedResult.item.key);
      setSearchInput(sortedResult.item.name);
      setInputs({
        ...inputs,
        meatType: { key: sortedResult.item.key, name: sortedResult.item.name },
      });
      setValidation(true);

      // TODO should this be the case???
      handleSubmit(
        setResults,
        {
          ...inputs,
          meatType: {
            key: sortedResult.item.key,
            name: sortedResult.item.name,
          },
        },
        e
      );
    }
  };

  const iteratePotentialResults = (meatData) => {
    potentialResults = [];
    searchResults = [];
    scoredResults = [];
    sortedResults = [];
    processedResults = [];
    console.log("Step 2", meatData, "Meat Data");
    meatData.forEach((item) => {
      potentialResults.push(item);
    });
    console.log("Step 4", potentialResults, "Potential Results");
  };

  // At search results, we compare each potential result with the search input
  const createSearchResults = () => {
    searchResults = [];
    console.log(
      "Step 4a",
      potentialResults,
      searchInput,
      "Potential Results and Search Input"
    );

    // Generate an array, with each word from the input field split
    let searchInputArray = searchInput.split(" ");
    console.log(searchInputArray, "searchInputArray");

    // For each potential result, see if it matches a word from the input field
    potentialResults.forEach((item) => {
      let inSearchResults = false;

      // If the user hasn't entered anything into the search field, create list of top 6 results
      if (searchInputArray[0] === "") {
        console.log("searchInputArray === ['']");
        popularResults.forEach((element) => {
          if (item.name.toLowerCase() === element.toLowerCase()) {
            inSearchResults = true;
          }
        });
        if (inSearchResults) {
          searchResults.push(item);
        }
      } else {
        console.log("searchInputArray !== ['']");
        searchInputArray.forEach((element) => {
          if (
            item.name.toLowerCase().includes(element.toLowerCase()) &&
            element.toLowerCase() !== ""
          ) {
            inSearchResults = true;
          }
          let wordMatchScore = item.name
            .toLowerCase()
            .score(element.toLowerCase(), 0.5);
          if (wordMatchScore > 0.4) {
            inSearchResults = true;
          }
        });
        if (inSearchResults) {
          searchResults.push(item);
        }
      }
    });

    console.log("Step 5b", searchResults, "Search Results");
  };

  const scoreResults = () => {
    searchResults.forEach((item) => {
      let score = { key: 0, reason: [] };

      // // Scoring: 1 if a match is found
      // score.key = 1;
      // score.reason.push(["Initially a match", 1]);

      // -0.05 per character in length
      score.key = score.key - 0.05 * item.name.length;
      score.reason.push(["Characters length", -0.05 * item.name.length]);

      // +2 if an exact match
      if (searchInput.toLowerCase() === item.name.toLowerCase()) {
        score.key += 2;
        score.reason.push(["Exact Match", 2]);
      }

      // + Match Score
      let matchScore = searchInput.toLowerCase().score(item.name.toLowerCase());
      score.key += matchScore;
      score.reason.push(["matchScore", matchScore]);

      // + Match Score per word
      searchInput.split(" ").forEach((searchWord) => {
        if ((searchWord !== " ") & (searchWord !== NaN) & (searchWord !== "")) {
          let wordMatchScore = 0;
          item.name.split(" ").forEach((itemWord) => {
            let newScore = itemWord
              .toLowerCase()
              .score(searchWord.toLowerCase(), 0.5);
            let reverseScore = searchWord
              .toLowerCase()
              .score(itemWord.toLowerCase(), 0.5);
            wordMatchScore += newScore + reverseScore;
            score.reason.push([
              `${itemWord} Matches the word ${searchWord}`,
              searchWord,
              newScore + reverseScore,
            ]);
          });
          score.key += wordMatchScore;
        }
      });

      // +0.5 if first letters match
      if (
        item.name
          .toLowerCase()
          .slice(0, searchInput.length)
          .includes(searchInput.toLowerCase()) === true
      ) {
        score.key = score.key + 0.5;
        score.reason.push(["First letters match", 0.5]);
      }

      // +0.05X per Char if matching a specific word
      searchInput.split(" ").forEach((element) => {
        let wordMatches =
          item.name.toLowerCase().split(element.toLowerCase()).length - 1;
        score.key += wordMatches * element.length * 0.5;
        score.reason.push([
          `Matches the word ${element} a total of ${wordMatches} times`,
          wordMatches * element.length * 0.05,
        ]);
      });

      // Divide score per word in the potential match
      score.key = score.key - (item.name.split(" ").length - 1) * 0.5;
      score.reason.push([
        "Number of words",
        -(item.name.split(" ").length - 1) * 0.5,
      ]);

      // console.log("Step 7a", scoredResults);
      scoredResults.push({
        item: item,
        score: score.key,
        reason: score.reason,
      });
    });
    console.log("Step 7b", scoredResults, "Scored Results");
  };

  const sortResults = () => {
    if (searchInput !== "") {
      sortedResults = scoredResults.sort(function (a, b) {
        return b.score - a.score;
      });
      console.log("Step 9a", sortedResults, "Sorted Results");
    } else {
      sortedResults = scoredResults;
    }
  };

  const processResults = () => {
    console.log("Processing results.", pickedMeat, sortedResults.length);

    // if (pickedMeat !== "") {
    //   processedResults = [];
    // } else {
    if (sortedResults.length < 1) {
      processedResults[0] = (
        <ListGroup.Item className="meatSearchResultError">
          No Results Found{" "}
          <span
            className="meatSearchResultErrorLink hoverLink"
            onClick={() => {
              setSearchInput("");
            }}>
            Clear Search Bar
          </span>
        </ListGroup.Item>
      );
    } else {
      for (let i = 0; i < Math.min(sortedResults.length, 6); i++) {
        processedResults[i] = (
          <ListGroup.Item
            className={`meatSearchResult 
              `}
            // ${keyNavigation === i + 1 ? "keyNavigationSelectedResult" : ""}
            onClick={(e) => handlePickedMeat(e, sortedResults[i])}
            value={sortedResults[i].item.name}
            shortname={sortedResults[i].item.key}
            key={i}>
            {sortedResults[i].item.name}
          </ListGroup.Item>
        );
      }
    }
    console.log("Processed results length", processedResults.length);
  };

  // ABOVE: ALL FUNCTIONS

  const generateSearchResults = () => {
    console.log("Step 1", allData.meatData);
    iteratePotentialResults(allData.meatData);
    console.log("Step 6");
    createSearchResults();
    console.log("Step 7");
    scoreResults();
    console.log("Step 8");
    sortResults();
    console.log("Step 9");
    processResults();
    console.log("Step 10");
  };

  generateSearchResults();

  return (
    <Form.Group className={"inputFormGroup"} ref={myElementRef}>
      {renderPage != "Results" ? (
        <FloatingLabel
          className="searchBarFloatingText"
          controlId="floatingInput"
          label={
            <div className="searchBarLabel">
              <i className="bi bi-search searchBarIcon"></i>
              <span className="searchBarText">Enter Ingredient</span>
            </div>
          }>
          <Form.Control
            maximumScale="1"
            id="searchBar"
            className={`searchBar 
          ${pickedMeat !== "" ? "meatSearchPicked" : ""}
          ${validation === false ? "meatSearchValidation" : ""}
          ${
            renderPage === "Results"
              ? "meatSearchResultsPage"
              : "meatSearchMainPage"
          }
          `}
            autofill="off"
            autoComplete="off"
            spellCheck="false"
            autoCapitalize="on"
            type="text"
            onClick={() => {
              handleScroll();
              setboxFocus(true);
            }}
            onBlur={() => {
              setTimeout(function () {
                setboxFocus(false);
              }, 200);
            }}
            onChange={handleSearchInput}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                pickTopSearchItem(event);
                setboxFocus(false);
              }
            }}
            value={searchInput}
            name="searchBar"
            placeholder="Ingredient to substitute"></Form.Control>{" "}
        </FloatingLabel>
      ) : (
        <>
          {" "}
          <Form.Control
            id="searchBar"
            className={`searchBar 
      ${pickedMeat !== "" ? "meatSearchPicked" : ""}
      ${validation === false ? "meatSearchValidation" : ""}
      ${
        renderPage === "Results"
          ? "meatSearchResultsPage"
          : "meatSearchMainPage"
      }
      `}
            autofill="off"
            autoComplete="off"
            spellCheck="false"
            autoCapitalize="on"
            type="text"
            // onFocus={() => }
            onClick={() => {
              setboxFocus(true);
            }}
            onBlur={() => {
              setTimeout(function () {
                setboxFocus(false);
              }, 200);
            }}
            onChange={handleSearchInput}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                pickTopSearchItem(event);
                setboxFocus(false);
              }
            }}
            value={searchInput}
            name="searchBar"
            placeholder="Ingredient to substitute"></Form.Control>
          {/* {boxFocus ? ( */}
          <i
            className="bi bi-x-square-fill search_bar_x_mark"
            onClick={() => {
              setSearchInput("");
            }}></i>
          {/* ) : (
            <></>
          )} */}
        </>
      )}
      {validation === false ? (
        <>
          <Form.Text className="meatSearchError">
            You need to {searchInput === "" ? "search for" : "pick"} an
            ingredient.
          </Form.Text>
          <br />
        </>
      ) : (
        ""
      )}

      {processedResults.length && boxFocus ? (
        <>
          {renderPage == "Results" ? <></> : <></>}
          {searchInput === "" && renderPage != "Results" ? (
            <>
              <p className="smallGreyText">Popular Searches:</p>
              <br />
            </>
          ) : (
            <></>
          )}
          <Card className="meatSearchResultsBoxCard">
            <ListGroup className="meatSearchResultsBox" variant="flush">
              {processedResults}
            </ListGroup>
          </Card>
        </>
      ) : null}

      <div className="searchBarSubtitle ">
        {boxFocus && renderPage != "Results" ? (
          <div className="smallGreyText alternativeSearch pt-0">
            Or, show{" "}
            <a
              onClick={(e) => {
                pickAllResults(e);
              }}>
              all results
            </a>
            .
          </div>
        ) : renderPage === "Results" ? (
          <></>
        ) : (
          <div className="pt-3">
            Type in a meat based ingredient and we’ll find you a meat-free
            alternative.
          </div>
        )}
      </div>
    </Form.Group>
  );
}
