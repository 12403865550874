import React from "react";
import siteContent from "../Components/Data/siteContent";

export default function AboutPage() {
  return (
    <>
      <h1 className="titleText centeredText mt-5 m-3">About Us</h1>
      <div className="contentContainer">
        <div className="aboutUsBoxContainer">
          <div className="aboutUsBox ">
            <h4>
              Made with <i class="bi bi-heart-fill"></i> in the United Kingdom
            </h4>
            <p>
              We've a young couple from the United Kingdom who are passionate
              about building tools that are fun to use. We've poured our all
              into MMMM; we will always strive to do the best we can, for our
              users, for animals, and for the planet.{" "}
            </p>
          </div>
          <div className="aboutUsBox">
            <h4>No more compromises</h4>
            <p>
              At Make My Meal Meatless we are convinced that eating vegetarian
              and vegan foods doesn’t need to be a compromise. We believe you
              can change what you eat without changing what you are used to.
            </p>
          </div>
          <div className="aboutUsBox">
            <h4>Delicious food, always</h4>
            <p>
              The aim of MMMM is more than just finding something to eat, it’s
              about making delicious food. Our mission is to help people create
              the classic recipes they love whilst saving animals lives,
              reducing the impact on the planet and improving health.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
