import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Search from "./Search";
import allData from "./Data/database";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "./errorBoundary";
import ReactGA from "react-ga";

// InputForm returns form elements for user inputs such as meal type and meat
export default function InputForm({
  results,
  setResults,
  setInputs,
  inputs,
  searchInput,
  setSearchInput,
  pickedMeat,
  setPickedMeat,
  renderPage,
}) {
  let history = useHistory();

  const [validation, setValidation] = useState(true);

  console.log(allData, "allData");

  function handleSubmit(setResults, inputs, e) {
    e.preventDefault();

    console.log("handle submit fired", inputs);

    if (inputs.meatType.key === "") {
      setValidation(false);
      return null;
    } else if (inputs.meatType.key === "all") {
      // We just collate all the potential results if the user asks for all of them.
      let substitute_foods = [];

      // Look for the meat in the vegetarian alternativeTo property
      allData.alternativeFoodData.forEach((element) => {
        substitute_foods.push(element);
      });

      setResults(
        Object.assign(results, {
          alternativeFoods: substitute_foods,
          gotResults: true,
          meatType: "all",
        })
      );
      ReactGA.event({
        category: "Anonymous User",
        action: "Clicked go on input page",
        label: "all",
      });
    } else {
      let meatType = inputs.meatType;

      let substitute_foods = [];

      // Look for the meat in the vegetarian alternativeTo property

      allData.alternativeFoodData.forEach((element) => {
        if (element.alternativeTo !== undefined) {
          element.alternativeTo.forEach((meatToCheck) => {
            if (meatToCheck === meatType.key) {
              substitute_foods.push(element);
            }
          });
        }
      });

      setResults(
        Object.assign(results, {
          alternativeFoods: substitute_foods,
          gotResults: true,
          meatType: meatType.key,
        })
      );
      ReactGA.event({
        category: "Anonymous User",
        action: "Clicked go on input page",
        label: meatType.key,
      });
    }
    history.push("/results/" + results.meatType);
  }

  return (
    <ErrorBoundary>
      {/* Displays the input form for the user on the mainpage, with a search bar, search results and submission button. */}
      <Form
        onSubmit={(e) => handleSubmit(setResults, inputs, e)}
        className={`inputFormFlex ${
          renderPage === "Results"
            ? "meatSearchResultsPage"
            : "meatSearchMainPage"
        }`}>
        <Search
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          pickedMeat={pickedMeat}
          setPickedMeat={setPickedMeat}
          inputs={inputs}
          setInputs={setInputs}
          validation={validation}
          setValidation={setValidation}
          handleSubmit={handleSubmit}
          setResults={setResults}
          renderPage={renderPage}
        />
        {/* <Form.Group className="inputFormGroup">
          <div className="buttonFlex">
            <Button
              style={{ marginRight: "auto" }}
              variant="warning"
              className="goButton accentButton"
              type="submit">
              Find an alternative{" "}
            </Button>
          </div>
        </Form.Group> */}
      </Form>
    </ErrorBoundary>
  );
}
