// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC9nXszXsekPdWCozFnMPapKo10niVdvcw",
  authDomain: "make-my-meal-meatless.firebaseapp.com",
  projectId: "make-my-meal-meatless",
  storageBucket: "make-my-meal-meatless.appspot.com",
  messagingSenderId: "409381319687",
  appId: "1:409381319687:web:fe1c992254a323d8da66a2",
  measurementId: "G-TM48T3DGXW",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebase.auth();
export const firebaseAnalytics = firebase.analytics();
