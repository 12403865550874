import React, { useState } from "react";
import ResultModal from "../Components/ResultModal";
import ErrorBoundary from "../Components/errorBoundary";
import Results from "../Components/Results/Results";
import Disclaimer from "../Components/Disclaimer";
import ReactGA from "react-ga";

export default function ResultsPage(props) {
  const [modalShow, setterModalShow] = useState([
    false,
    { name: "", brand: "" },
  ]);

  function setModalShow(setModal, product) {
    if (product != "Null") {
      console.log("logged", product);
      ReactGA.event({
        category: "Anonymous User",
        action: "Clicked on a product modal",
        label: product,
      });
    }
    setterModalShow(setModal);
  }

  return (
    <ErrorBoundary>
      <div className="resultsContentContainer">
        <div
          className="backgroundContentContainerResults"
          style={{ marginTop: "20px" }}>
          <Results {...props} setModalShow={setModalShow} />
        </div>
        <Disclaimer />
        <ResultModal
          show={modalShow[0]}
          data={modalShow[1]}
          onHide={() => setModalShow([false, { name: "", brand: "" }], "Null")}
        />
      </div>
    </ErrorBoundary>
  );
}
