import { Modal, Button } from "react-bootstrap";
import ImgWithPlaceholder from "./ImgWithPlaceholder";
import { AltShops, AltInfo } from "./Results/Substitutes";
import { v4 as uuidv4 } from "uuid";

function ResultModal(props) {
  let substitute = props.data;
  if (props.show) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {substitute.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="altFoodTextContainer">
            <div className="altFoodSplit">
              <div className="altFoodBrand">Brand: {substitute.brand}</div>
              <div className="altFoodInfoContainer">
                <AltInfo
                  key={uuidv4()}
                  info={[].concat(
                    // altData.storage.map((el) => allData.storageData[el].name),
                    substitute.suitableFor
                    // .map((el) =>
                    //   allData.dietaryData.forEach((item) => {
                    //     if
                    //   })
                    // )
                    // .concat([infoWeight])
                  )}
                />
              </div>
            </div>
          </div>
          <ImgWithPlaceholder
            alt={"Image of " + substitute.name}
            className="altFoodImg"
            rel="noreferrer"
            src={substitute.img}
          />
          <br />
          <b>Buy At:</b>
          <br />
          <br />
          <div className="altFoodShopsContainer">
            <AltShops altData={substitute} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}

export default ResultModal;
