import { v4 as uuidv4 } from "uuid";
import allData from "../Data/database";
import advertData from "../Data/advertData";
import ImgWithPlaceholder from "../ImgWithPlaceholder";
import LoadMoreButton from "./LoadMoreButton";
import ReactGA from "react-ga";

// Substitutes helper functions

export function AltInfo({ info }) {
  let altInfoItems = [];

  info.forEach((el) => {
    if (el !== null) {
      altInfoItems.push(
        <div className="altFoodInfo" key={uuidv4()}>
          {el}
        </div>
      );
      altInfoItems.push(
        <div className="altFoodInfo" key={uuidv4()}>
          &#183;
        </div>
      );
    }
  });
  return altInfoItems.slice(0, -1);
}

export function AltShops({ altData, setModalShow }) {
  let altShopItems = [];
  let shopName = altData.shop;
  allData.shopData.forEach((shop) => {
    if (shop.key === altData.shop) {
      shopName = shop.name;
    }
  });
  console.log(altData);
  let price = altData.prices[altData.shop].toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
  });
  Object.keys(altData.prices).forEach((shop) => {
    altShopItems.push(
      <a
        className="altFoodShop"
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          ReactGA.event({
            category: "Anonymous User",
            action: "Clicked on a link on a product",
            label: `${altData.name}, clicked through ${shop}`,
          });
          console.log(altData.name, shop);
          setModalShow([false, { name: "", brand: "" }], "Null");
        }}
        href={altData.urls[shop]}
        key={uuidv4()}>
        <div className="altFoodShopHolder">
          <img
            alt="Shop Brand Logo"
            className="altFoodShopImg"
            src={
              shop === "tesco"
                ? "/svgIcons/tesco.png"
                : shop === "ocado"
                ? "https://www.ocado.com/webshop/static/images/logos/brandLogo2.png"
                : "/svgIcons/amazon.png"
            }></img>
        </div>
        <div>
          <span
            style={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}>
            {" "}
            {price}{" "}
          </span>{" "}
          <span className="altFoodPricePer" key={uuidv4()}>
            £0.94/100g
          </span>
        </div>
      </a>
    );
  });

  return altShopItems;
}

function SubstituteFood({ substitute, loadMore, setLoadMore, setModalShow }) {
  if (substitute === "Advert") {
    let advert = advertData[Math.floor(Math.random() * advertData.length)];

    return (
      <div
        className="altFoodContainer"
        style={{ padding: "10px 10px 0px 10px" }}>
        <div className="altFoodTextContainer" style={{ padding: "0px" }}>
          <div className="advertSplit">{advert.advertCode}</div>
          <div className="advertDescription">
            <div className="altFoodBorder" />
            <div className="altFoodInfo">
              Advertisement · {advert.advertiserName}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (substitute === "LoadMoreButton") {
    return <LoadMoreButton loadMore={loadMore} setLoadMore={setLoadMore} />;
  } else {
    function modal_handler(e) {
      let child_buttons = document.getElementsByClassName("altFoodShop");
      let run = true;

      for (let i = 0; i < child_buttons.length; i++) {
        if (child_buttons[i].contains(e.target)) {
          run = false;
        }
      }

      if (run) {
        setModalShow([true, substitute], substitute.name);
      }
    }

    return (
      <div
        className="altFoodContainer"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          modal_handler(e);
        }}>
        <div className="altFoodTextContainer">
          <div className="altFoodSplit">
            <div className="altFoodBrand">{substitute.brand}</div>
            <div className="altFoodName">{substitute.name}</div>
            <div className="altFoodBorder" />
            <div className="altFoodInfoContainer">
              <AltInfo
                key={uuidv4()}
                info={[].concat(
                  // altData.storage.map((el) => allData.storageData[el].name),
                  substitute.suitableFor
                  // .map((el) =>
                  //   allData.dietaryData.forEach((item) => {
                  //     if
                  //   })
                  // )
                  // .concat([infoWeight])r
                )}
              />
            </div>
          </div>
          <div className="altFoodSplit">
            <div className="altFoodShopsContainer">
              <AltShops
                key={uuidv4()}
                altData={substitute}
                setModalShow={setModalShow}
              />
            </div>

            {/* <div className="altFoodPricePer" key={uuidv4()}>
              From £0.94/100g
            </div> */}
          </div>
        </div>
        <div className="altFoodImgContainer">
          <ImgWithPlaceholder
            alt={"Image of " + substitute.name}
            className="altFoodImg"
            rel="noreferrer"
            src={substitute.img}
          />
        </div>
      </div>
    );
  }
}

export default function RenderSubstitutes({
  loadedSubstitutes,
  loadMore,
  setLoadMore,
  setModalShow,
}) {
  let substitutesRendered = [];

  loadedSubstitutes.forEach((el) => {
    substitutesRendered.push(
      <SubstituteFood
        key={uuidv4()}
        substitute={el}
        loadMore={loadMore}
        setLoadMore={setLoadMore}
        setModalShow={setModalShow}
      />
    );
  });

  return <>{substitutesRendered}</>;
}
