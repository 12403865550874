import { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "animate.css";

export default function Header({ resetInputs }) {
  const [showEventBar, setShowEventBar] = useState(true);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="headerContainer">
      <div className="headerTitleContainer">
        <Navbar expanded={expanded} variant="light" expand="md" className="">
          <Navbar.Brand
            as={NavLink}
            className="brandText"
            to="/main"
            onClick={() => {
              resetInputs();
            }}>
            <img className="MMMMHeaderIcon" src="MMMM Icon.png"></img>
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            id="navbarBtn"
            aria-controls="basic-navbar-nav"
            className="justify-content-end"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end">
            <Nav.Link
              onClick={() => {
                resetInputs();
                setExpanded(false);
              }}
              as={NavLink}
              className="headerLink"
              exact
              activeClassName="headerLinkActive"
              to="/main">
              Search{" "}
              <i className="bi bi-search" style={{ fontSize: "80%" }}></i>
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                resetInputs();
                setExpanded(false);
              }}
              as={NavLink}
              className="headerLink"
              exact
              activeClassName="headerLinkActive"
              to="/about">
              About Us
            </Nav.Link>
            <Nav.Link
              EventKey="3"
              onClick={() => {
                resetInputs();
                setExpanded(false);
              }}
              as={NavLink}
              className="headerLink"
              exact
              activeClassName="headerLinkActive"
              to="/faqs">
              FAQs
            </Nav.Link>
            <Nav.Link
              EventKey="4"
              onClick={() => {
                resetInputs();
                setExpanded(false);
              }}
              as={NavLink}
              className="headerLink"
              exact
              activeClassName="headerLinkActive"
              to="/contactus">
              Contact Us
            </Nav.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
      {showEventBar ? (
        <div className="eventContainer">
          Make My Meal Meatless officially launches later in 2023! Click{" "}
          <a
            className="eventLink"
            href="http://eepurl.com/hYH-Nv"
            target="_blank">
            here
          </a>{" "}
          to stay in the loop!
          <img
            src="X mark.png"
            height="10px"
            className="eventBarX"
            onClick={() => {
              setShowEventBar(false);
            }}
          />
        </div>
      ) : (
        <div className="headerBorderContainer"></div>
      )}
    </div>
  );
}
