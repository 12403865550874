import siteContent from "./Data/siteContent";

export default function LearnMore() {
  return (
    <>
      <div className="backgroundContentContainer">
        <h3 className="subtitleText leftText">Learn more about us</h3>
        <br />
        <p className="infoText">{siteContent.aboutUs}</p>
        <p className="infoText">{siteContent.contactUs}</p>
        <p className="infoText">{siteContent.supportUs}</p>
      </div>
      {/* <div className="backgroundContentContainer">
        <h3 className="subtitleText leftText">FAQs</h3>
        <br />
        <p className="infoText">{siteContent.faqs}</p>
      </div> */}
    </>
  );
}
