import siteContent from "./Data/siteContent";

export default function Disclaimer() {
  return (
    <div className="backgroundContentContainer disclaimerText">
      <h6 className="leftText">Disclaimer</h6>
      {siteContent.disclaimer}
    </div>
  );
}
