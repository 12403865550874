import React from "react";
import ErrorBoundary from "./errorBoundary";

export default function InfoPageTemplate({ children, advert }) {
  return (
    <ErrorBoundary>
      <div className="contentContainer">
        <div className="backgroundContentContainer">{children}</div>
        {/* {advert ? (
          <div className="backgroundContentContainer">
            <amp-ad
              width="100vw"
              height="320"
              type="adsense"
              data-ad-client="ca-pub-9797460845647267"
              data-ad-slot="7684147469"
              data-auto-format="rspv"
              data-full-width="">
              <div overflow=""></div>
            </amp-ad>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </ErrorBoundary>
  );
}
