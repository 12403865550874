const advertData = [
  {
    advertiserName: "Abel and Cole",
    advertCode: (
      <a
        rel="sponsored"
        href="https://www.awin1.com/cread.php?s=3045667&v=6388&q=428400&r=956725">
        <img
          src="https://www.awin1.com/cshow.php?s=3045667&v=6388&q=428400&r=956725"
          border="0"
        />
      </a>
    ),
  },
  {
    advertiserName: "Vegan Outfitters",
    advertCode: (
      <a
        rel="sponsored"
        href="https://www.awin1.com/cread.php?s=3075754&v=20371&q=431511&r=956725">
        <img
          src="https://www.awin1.com/cshow.php?s=3075754&v=20371&q=431511&r=956725"
          border="0"
        />
      </a>
    ),
  },
  {
    advertiserName: "Planet Organic",
    advertCode: (
      <a
        rel="sponsored"
        href="https://www.awin1.com/cread.php?s=2987037&v=6179&q=420698&r=956725">
        <img
          src="https://www.awin1.com/cshow.php?s=2987037&v=6179&q=420698&r=956725"
          border="0"
        />
      </a>
    ),
  },
  {
    advertiserName: "Crafty Nectar",
    advertCode: (
      <a
        rel="sponsored"
        href="https://www.awin1.com/cread.php?s=2986990&v=24266&q=423095&r=956725">
        <img
          src="https://www.awin1.com/cshow.php?s=2986990&v=24266&q=423095&r=956725"
          border="0"
        />
      </a>
    ),
  },
  {
    advertiserName: "Traidcraft",
    advertCode: (
      <a
        rel="sponsored"
        href="https://www.awin1.com/cread.php?s=2952853&v=22141&q=419606&r=956725">
        <img
          src="https://www.awin1.com/cshow.php?s=2952853&v=22141&q=419606&r=956725"
          border="0"
        />
      </a>
    ),
  },
  {
    advertiserName: "Mighty Pea",
    advertCode: (
      <a
        rel="sponsored"
        href="https://www.awin1.com/cread.php?s=2924712&v=24179&q=416995&r=956725">
        <img
          src="https://www.awin1.com/cshow.php?s=2924712&v=24179&q=416995&r=956725"
          border="0"
        />
      </a>
    ),
  },
  {
    advertiserName: "Planty",
    advertCode: (
      <a
        rel="sponsored"
        href="https://www.awin1.com/cread.php?s=2932505&v=22512&q=408572&r=956725">
        <img
          src="https://www.awin1.com/cshow.php?s=2932505&v=22512&q=408572&r=956725"
          border="0"
        />
      </a>
    ),
  },
];

export default advertData;
