import React from "react";

export default function ContactUsPage() {
  return (
    <>
      <h1 className="titleText centeredText">404 Error - Page Not Found!</h1>
      <p className="infoText">
        Oops - we can't find the page you are looking for.
        <a href="/main" className="hoverLink">
          Click here to go back to the homepage
        </a>
        <br />
        <br />
      </p>
    </>
  );
}
