import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Stylesheets/output.css";
import PageHandler from "./PageHandler";
import ReactGA from "react-ga";

ReactGA.initialize("UA-210877233-1");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <PageHandler />
  </React.StrictMode>,
  document.getElementById("root")
);
