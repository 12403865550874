import React, { useState } from "react";
import { Placeholder } from "react-bootstrap";

export default function ImgWithPlaceholder(props) {
  const [loaded, setloaded] = useState(false);

  function setLoadToTrue() {
    setloaded(true);
  }

  return (
    <>
      <img
        src={props.src}
        alt={props.alt}
        className={props.className}
        onLoad={setLoadToTrue}
      />
      {!loaded && <Placeholder className="altFoodImgPlaceholder" />}
    </>
  );
}
