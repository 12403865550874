export default function NoResults({ goToMainpage }) {
  return (
    <div className="resultsBox">
      <p>
        No results available. Go
        <span
          style={{ textDecoration: "underline" }}
          className="hoverLink"
          onClick={goToMainpage}>
          back
        </span>
        to the inputs page to generate results.
      </p>
    </div>
  );
}
