import { Form, Button } from "react-bootstrap";
import Demo from "./MailchimpSignupForm";

function NewsletterForm() {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}

export default function Footer() {
  let year = new Date().getFullYear();
  return (
    <div className="footerColour">
      <div className="footerContainer">
        <div className="footerTop">
          <div className="footerTopLeft">
            <div className="footerTopIconText">
              <div className="footerMMMMIcon">
                <img src="logo192black.png" width="55px" height="55px"></img>
              </div>
              <div className="footerMMMMText">
                Make My
                <br />
                Meal Meatless
              </div>
            </div>
            <div className="footerMMMMSubtext">
              We are changing the way people find meat-free food. Convert
              meat-based ingredients into alternatives, find new products and
              easily shop for the products you love.
            </div>
          </div>
          <div className="footerTopRight">
            <p className="footerFormTitle">
              STAY UPDATED <i>with all things meatless</i>
            </p>
            <Demo />

            <p className="footerFormSubtext">
              Signup to the MMMM Newsletter to hear when exciting new features
              are built, supermarkets added, product roundups and more.
              Unsubscribe whenever you want.
            </p>
          </div>
        </div>
        <div className="footerLine"></div>
        <div className="footerBottom">
          <div className="footerBottomTextFull">
            © Make My Meal Meatless, {year}. All rights reserved |{" "}
            <a
              className="footerFeedbackLink"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdxcWFkNJ8tc25R3Iv144RizrM7fy6WWTN7K9fVwBsL6ahl9w/viewform"
              target="_blank">
              <i>Send Feedback</i>
            </a>
          </div>
          <div className="footerBottomTextMobile">
            © {year} |{" "}
            <a
              className="footerFeedbackLink"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdxcWFkNJ8tc25R3Iv144RizrM7fy6WWTN7K9fVwBsL6ahl9w/viewform"
              target="_blank">
              <i>Send Feedback</i>
            </a>
          </div>
          <div className="footerBottomSocials">
            <a
              href="https://www.instagram.com/makemymealmeatless/"
              target="_blank">
              <i class="bi bi-instagram"></i>
            </a>{" "}
            <a href="mailto:makemymealmeatless@gmail.com" target="_blank">
              <i class="bi bi-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
