import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import productData from "./Data/substituteData.json";
import meatData from "./Data/meatData.json";
import shopData from "./Data/shopData.json";
import brandData from "./Data/brandData.json";

function MeatTable() {
  return (
    <>
      <h3>Meat Table</h3>
      <Table className="adminTable" striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>CO2 (kg)</th>
            <th>Calories</th>
            <th>Not Suitable For</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          {meatData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.Co2 || "-"}</td>
              <td>{item.calories || "-"}</td>
              <td>{item.notSuitableFor?.join(", ") || "-"}</td>
              <td>{item.category || "-"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

function ShopTable() {
  return (
    <>
      <h3>Shop Table</h3>
      <Table className="adminTable" striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {shopData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.url || "-"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

function BrandTable() {
  return (
    <>
      <h3>Brand Table</h3>
      <Table className="adminTable" striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Alternative Names</th>
          </tr>
        </thead>
        <tbody>
          {brandData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.altNames?.join(", ") || "-"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default function ProductsTable() {
  return (
    <>
      <h3>Products Table</h3>
      <Table className="adminTable" striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Weight</th>
            <th>Brand</th>
            <th>Price</th>
            <th>Shops</th>
            <th>Availability</th>
            <th>Alternatives</th>
          </tr>
        </thead>
        <tbody>
          {productData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.weight || "-"}</td>
              <td>{item.brand || "-"}</td>
              <td>{item.prices?.ocado || "-"}</td>
              <td>{item.shop || "-"}</td>
              <td>{item.availability?.ocado || "-"}</td>
              <td>{item.alternativeTo?.join(", ") || "-"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export { ShopTable, BrandTable, ProductsTable, MeatTable };
