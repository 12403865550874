import React, { useState } from "react";
import allData from "../Data/database";
import { useHistory, useParams } from "react-router-dom";
import RenderOptions from "./SearchAndFilters";
import RenderFilters from "./FiltersSelected";
import RenderSubstitutes from "./Substitutes";
import NoResults from "./NoResults";
import { Button } from "react-bootstrap";

// Results Helper Functions:

// This function updates the state to force a render when necessary
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

// This function takes the list of filters and sorts them by alphabetical order
function sortFiltersAlphabetically(filterList) {
  filterList.sort((a, b) => a.name.localeCompare(b.name));
  return filterList;
}

// This function takes a prices object and returns the lowest available price
function returnLowestPrice(pricesObject) {
  return Math.min(...Object.values(pricesObject));
}

// This function adds an object to an array if it isn't there already. If it is there, it updates the object in the array
function pushToArray(arr, obj) {
  const index = arr.findIndex((e) => e.name === obj.name);

  if (index === -1) {
    arr.push(obj);
  } else {
    arr[index] = obj;
  }
}

// This function removes all the user selected filters
function wipe_filters(results, setResults) {
  setResults({
    ...results,
    resultsFilters: {
      sort: [],
      brand: [],
      shop: [],
      suitableFor: [],
    },
  });
}

// This function sorts meat substitutes based on the filters the user has applied
function sortSubstitutes(filters, filteredSubstitutes) {
  let sortedSubstitutes = JSON.parse(JSON.stringify(filteredSubstitutes));

  console.log("Subsitute sorting started", filteredSubstitutes, filters);

  sortedSubstitutes = filteredSubstitutes.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  if (filters.sort.includes("Price: High to Low")) {
    sortedSubstitutes = filteredSubstitutes.sort(
      (a, b) => returnLowestPrice(b.prices) - returnLowestPrice(a.prices)
    );
  }

  if (filters.sort.includes("Price: Low to High")) {
    sortedSubstitutes = filteredSubstitutes.sort(
      (a, b) => returnLowestPrice(a.prices) - returnLowestPrice(b.prices)
    );
  }

  console.log("Subsitute sorting complete", sortedSubstitutes, filters);

  return sortedSubstitutes;
}

// This function checks if a substitute should be displayed based on the filters that are currently selected by the user
function checkFilters(substitute, results_filters) {
  let validated = true;

  Object.keys(results_filters).forEach((key) => {
    if (key !== "sort") {
      // For each filter, check if the item matches at least one of the picked options
      let validatedFilter = false;
      if (results_filters[key].length == 0) {
        validatedFilter = true;
      } else {
        results_filters[key].forEach((item) => {
          if (Array.isArray(substitute[key])) {
            if (substitute[key].includes(item)) {
              validatedFilter = true;
            }
          } else {
            if (substitute[key] == item) {
              validatedFilter = true;
            }
          }
        });
      }
      if (validatedFilter == false) {
        validated = false;
      }
    }
  });
  return validated;
}

function addAdvertsToSubstitutes(sortedSubstitutes) {
  // Number of adverts to display parameter:
  let showAdEvery = 9;

  let advertsAndSubstitutes = [];

  sortedSubstitutes.forEach((substitute) => {
    if ((advertsAndSubstitutes.length % showAdEvery) + 1 === showAdEvery) {
      console.log("Advert found");
      advertsAndSubstitutes.push("Advert");
    }
    advertsAndSubstitutes.push(substitute);
  });

  return advertsAndSubstitutes;
}

function loadSubstitutes(advertsAndSubstitutes, loadMore, setLoadMore) {
  // Number of substitutes shown before load more button
  let noOfSubstitutesToLoad = 16;

  // TODO something here to load in only the correct number of substitutes
  let loadedSubstitutes = advertsAndSubstitutes;
  if (advertsAndSubstitutes.length > noOfSubstitutesToLoad * (1 + loadMore)) {
    loadedSubstitutes = advertsAndSubstitutes.slice(
      0,
      noOfSubstitutesToLoad * (1 + loadMore)
    );
    loadedSubstitutes.push("LoadMoreButton");
  }

  return loadedSubstitutes;
}

export default function Results(props) {
  const {
    results,
    setResults,
    inputs,
    setInputs,
    searchInput,
    setSearchInput,
    pickedMeat,
    setPickedMeat,
    setModalShow,
  } = props;

  let history = useHistory();

  const { id } = useParams();

  const goToPage = (page) => {
    history.push(page);
  };

  // If the user presses back, we send them back to the main page
  const goToMainpage = () => {
    history.push("/main");
  };

  const [loadMore, setLoadMore] = useState(0);

  const results_filters = results.resultsFilters;

  const forceUpdate = useForceUpdate();

  // When a user picks a filter they want to use, handle filter is fired
  const handleOption = (el, option, substitutes) => {
    console.log(el, option.key, option, "El, option.key");
    console.log("handlefilter", results);

    let newElResultsFilters = results_filters[el.key];
    if (!newElResultsFilters.includes(option.key)) {
      if (el.key === "sort") {
        newElResultsFilters = [];
      }
      newElResultsFilters.push(option.key);
    } else {
      newElResultsFilters = newElResultsFilters.filter(function (item) {
        return item !== option.key;
      });
    }

    let newResultsFilters = Object.assign(results_filters, {
      [el.key]: newElResultsFilters,
    });
    setResults(Object.assign(results, { resultsFilters: newResultsFilters }));
  };

  // If there are no results, we display the no results screen
  if (!results.gotResults && id === "") {
    return <NoResults goToMainpage={goToMainpage} />;

    // If there are results to display, we do the following:
  } else {
    // 1. We retrieve the item for the meat that was required (i.e. beef burger)
    let meat = {};

    if (id !== "") {
      allData.meatData.forEach((item) => {
        if (item.key === id) {
          meat = item;
        }
      });

      let substitute_foods = [];
      if (id !== "all") {
        // Look for the meat in the vegetarian alternativeTo property

        allData.alternativeFoodData.forEach((element) => {
          if (element.alternativeTo !== undefined) {
            element.alternativeTo.forEach((meatToCheck) => {
              if (meatToCheck === id) {
                substitute_foods.push(element);
              }
            });
          }
        });

        setResults(
          Object.assign(results, {
            alternativeFoods: substitute_foods,
            gotResults: true,
            meatType: id,
          })
        );
      } else {
        allData.alternativeFoodData.forEach((element) => {
          substitute_foods.push(element);
        });

        setResults(
          Object.assign(results, {
            alternativeFoods: substitute_foods,
            gotResults: true,
            meatType: id,
          })
        );
      }
    } else {
      allData.meatData.forEach((item) => {
        if (item.key === results.meatType) {
          meat = item;
        }
      });
    }

    // 2. We find the subsitutes from the results prop
    let substitutes = results.alternativeFoods;

    // 3. We build up the shops and brands to show in the options buttons depending on the substitutes
    let shop_data_from_substitutes = [];
    substitutes.forEach((el) => {
      allData.shopData.forEach((shop) => {
        Object.keys(el.availability).forEach((shopInEl) => {
          if (shop.key === shopInEl) {
            pushToArray(shop_data_from_substitutes, shop);
          }
        });
      });
    });

    let brand_data_from_substitutes = [];
    substitutes.forEach((el) => {
      pushToArray(brand_data_from_substitutes, {
        name: el.brand,
        key: el.brand,
      });
    });
    console.log("Part 3");

    // 4. We now build up the options available to the user:
    let options = [];

    options.push({
      key: "sort",
      translation: "sort",
      name: "Sort",
      options: sortFiltersAlphabetically([
        { name: "Default", key: "default" },
        { name: "Price: Low to High", key: "Price: Low to High" },
        { name: "Price: High to Low", key: "Price: High to Low" },
      ]),
    });

    options.push({
      key: "shop",
      translation: "shop",
      name: "Supermarket",
      options: sortFiltersAlphabetically(shop_data_from_substitutes),
    });

    options.push({
      translation: "suitableFor",
      key: "suitableFor",
      name: "Dietary Choice",
      options: sortFiltersAlphabetically(allData.dietaryData),
    });

    options.push({
      key: "brand",
      name: "Brand",
      options: sortFiltersAlphabetically(brand_data_from_substitutes),
    });

    console.log("Part 4");

    // 5. For every option other than the sort option, we set the 'number' of subsitutes that match to 0
    options.forEach((optionsObj) => {
      if (optionsObj.key !== "sort") {
        optionsObj.options.forEach((option) => {
          option.no = 0;
        });
      }
    });

    console.log("Part 5");

    // 6. Check if the substitutes match the filters that the user has picked
    let filteredSubstitutes = [];

    substitutes.forEach((substitute) => {
      if (checkFilters(substitute, results_filters)) {
        filteredSubstitutes.push(substitute);
      }
    });

    console.log("Part 7");

    // 7. Taking the list of filtered substitutes, we sort them according to the user's preferences
    let sortedSubstitutes = sortSubstitutes(
      results_filters,
      filteredSubstitutes
    );

    // 8. For every option we increase it's number by 1 if it matches a substitute. For example, the Beyond Meat brand number ticks up by 1 for every potential Beyond Meat product in the results.
    const setOptionNumber = (sortedSubstitutes, options) => {
      options.forEach((optionObj) => {
        if (optionObj.key !== "sort" && optionObj.key !== "shop") {
          optionObj.options.forEach((option) => {
            if (Array.isArray(sortedSubstitutes[optionObj.key])) {
              if (sortedSubstitutes[optionObj.key].includes(option.key)) {
                option.no += 1;
              }
            } else {
              if (sortedSubstitutes[optionObj.key] == option.key) {
                option.no += 1;
              }
            }
          });
        }
        if (optionObj.key == "shop") {
          optionObj.options.forEach((option) => {
            Object.keys(sortedSubstitutes.availability).forEach((el) => {
              console.log("key", el);
              if (el.includes(option.key)) {
                option.no += 1;
              }
            });
          });
        }
      });
    };

    sortedSubstitutes.forEach((sortedSubstitutes) => {
      setOptionNumber(sortedSubstitutes, options);
    });

    console.log("Part 8");

    // 9. Having filtered and sorted the substitutes, we add adverts to the results
    let advertsAndSubstitutes = addAdvertsToSubstitutes(sortedSubstitutes);

    console.log("Part 9", advertsAndSubstitutes);

    // 10. Finally we display the correct number of substitutes based on the load more button
    let loadedSubstitutes = loadSubstitutes(
      advertsAndSubstitutes,
      loadMore,
      setLoadMore
    );

    const handover_to_render_options = {
      results,
      setResults,
      inputs,
      setInputs,
      searchInput,
      setSearchInput,
      pickedMeat,
      setPickedMeat,
    };

    // 11. We render the results box:
    return (
      <>
        {/* <div className="advertHandler">
          <div className="nonAdvertContent"></div> */}
        <div className="resultsBox">
          <div className="resultsFiltersBox">
            <RenderOptions
              options={options}
              results_filters={results_filters}
              forceUpdate={forceUpdate}
              handleOption={handleOption}
              substitutes={substitutes}
              {...handover_to_render_options}
            />
            <RenderFilters
              results={results}
              setResults={setResults}
              forceUpdate={forceUpdate}
              handleOption={handleOption}
              wipe_filters={wipe_filters}
              substitutes={substitutes}
              options={options}
            />
          </div>
          <RenderSubstitutes
            options={options}
            sortSubstitutes={sortSubstitutes}
            loadedSubstitutes={loadedSubstitutes}
            loadMore={loadMore}
            setLoadMore={setLoadMore}
            setModalShow={setModalShow}
          />
        </div>
        <br />
        <div className="buttonFlex">
          <Button
            variant="warning"
            className="goButton accentButton"
            onClick={() => goToPage("/main")}>
            Back
          </Button>
        </div>
        <div />
      </>
    );
  }
}
