import React from "react";
import { Accordion } from "react-bootstrap";
import InfoPageTemplate from "../Components/InfoPageTemplate";
import siteContent from "../Components/Data/siteContent";

export default function FaqPage() {
  let content = siteContent.faqs.map((faq) => (
    <Accordion.Item
      className="faq-card"
      style={{ width: "100%" }}
      eventKey={faq}>
      <Accordion.Header>{faq.title}</Accordion.Header>
      <Accordion.Body style={{ marginLeft: "10px", fontSize: "0.9rem" }}>
        {faq.text}
      </Accordion.Body>
    </Accordion.Item>
  ));
  return (
    <>
      <h1 className="titleText centeredText mt-5 m-3">
        Frequently Asked Questions
      </h1>
      <h2 className="pageSubtitleText centeredText m-1">
        <i>Have questions? We’re here to help.</i>
      </h2>
      <br />

      <div className="contentContainer">
        <p className="infoText">
          <Accordion flush>{content}</Accordion>
        </p>
      </div>
    </>
  );
}
