import { Button } from "react-bootstrap";

export default function LoadMoreButton({ loadMore, setLoadMore }) {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Button
        className="accentButton goButton mt-3"
        variant="warning"
        onClick={() => setLoadMore(loadMore + 1)}>
        Load More Results
      </Button>
    </div>
  );
}
