import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import MainPage from "./Pages/MainPage";
import InputPage from "./Pages/InputPage";
import ResultsPage from "./Pages/ResultsPage";
import AboutPage from "./Pages/AboutPage";
import FaqPage from "./Pages/FaqPage";
import SupportUsPage from "./Pages/SupportUsPage";
import ContactUsPage from "./Pages/ContactUsPage";
import AdminPage from "./Pages/AdminPage";
import NoMatchPage from "./Pages/NoMatchPage";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import InfoPageTemplate from "./Components/InfoPageTemplate";
import ErrorBoundary from "./Components/errorBoundary";
import ScrollToTop from "./Components/ScrollToTop";
// import LearnMore from "./Components/LearnMore";

// The PageHandler holds the key inputs and results that are passed down through props to other components.
// All pages for the app are rendered and routed within PageHandler for this reason
export default function PageHandler() {
  const [searchInput, setSearchInput] = useState("");
  const [pickedMeat, setPickedMeat] = useState("");
  const [inputs, setInputs] = useState({
    convertTo: { key: "vegetarian", name: "Vegetarian" },
    meatType: { key: "", name: "" },
  });

  function resetInputs() {
    setSearchInput("");
    setPickedMeat("");
    setInputs({
      convertTo: { key: "vegetarian", name: "Vegetarian" },
      meatType: { key: "", name: "" },
    });
  }

  const [results, setResults] = useState({
    gotResults: false,
    resultsFilters: {
      sort: [],
      brand: [],
      shop: [],
      suitableFor: [],
    },
  });

  return (
    <ErrorBoundary>
      <Router>
        <ScrollToTop />
        <div className="pageContainer">
          <Header resetInputs={resetInputs} />
          <Switch>
            <Route exact path="/">
              <LandingPage
                results={results}
                setResults={setResults}
                inputs={inputs}
                setInputs={setInputs}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                pickedMeat={pickedMeat}
                setPickedMeat={setPickedMeat}
              />
            </Route>
            <Route exact path="/main">
              <MainPage
                results={results}
                setResults={setResults}
                inputs={inputs}
                setInputs={setInputs}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                pickedMeat={pickedMeat}
                setPickedMeat={setPickedMeat}
              />
            </Route>
            <Route exact path="/inputs">
              <InputPage
                results={results}
                setResults={setResults}
                inputs={inputs}
                setInputs={setInputs}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                pickedMeat={pickedMeat}
                setPickedMeat={setPickedMeat}
              />
            </Route>
            <Route exact path={"/results/:id"}>
              <ResultsPage
                results={results}
                setResults={setResults}
                inputs={inputs}
                setInputs={setInputs}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                pickedMeat={pickedMeat}
                setPickedMeat={setPickedMeat}
              />
            </Route>
            <Route exact path="/about">
              <AboutPage />
            </Route>
            <Route exact path="/faqs">
              <FaqPage />{" "}
            </Route>
            {/* <Route exact path="/supportus">
              <SupportUsPage />
            </Route> */}
            <Route exact path="/contactus">
              <ContactUsPage />{" "}
            </Route>
            <Route exact path="/admin">
              <InfoPageTemplate advert={false}>
                <AdminPage />
              </InfoPageTemplate>
            </Route>
            <Route path="*">
              <InfoPageTemplate advert={false}>
                <NoMatchPage />
              </InfoPageTemplate>
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </ErrorBoundary>
  );
}
