import React from "react";
import { Button } from "react-bootstrap";
import siteContent from "../Components/Data/siteContent";

export default function ContactUsPage() {
  return (
    <>
      <h1 className="titleText centeredText mt-5 m-3">Contact Us</h1>
      <div className="contentContainer">
        <div className="backgroundContentContainer">
          <p className="infoText">{siteContent.contactUs}</p>
          <div className="buttonFlexCenter">
            <Button
              target="blank"
              variant="warning"
              className="goButton accentButton grey-button-border"
              href="https://forms.gle/28Kp2gatVLNNsKvK9">
              Send Feedback <i class="bi bi-pencil"></i>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
