import React from "react";
import ErrorBoundary from "../Components/errorBoundary";
import { Button } from "react-bootstrap";

// Landing Page returns a alpha build splash screen with an email signup form and a link to MMMM Instagram
export default function LandingPage() {
  return (
    <ErrorBoundary>
      <div className="mainpageContentContainer">
        <div className="mainTextbox">
          <div className="landingPageBigText">
            Hello! Welcome to Make My Meal Meatless 🌱
            <br />
            <br />
            <div className="landingPageText">
              Here we take meat-based ingredients and suggest delicious meat
              free alternatives that taste just as good.
              <br />
              <br />
              We can't wait to share what we are building with everyone 🌍, and
              are hard at work making sure Make My Meal Meatless is ready soon.
              <br />
              <br />
              Visit our alpha release ⚙️ version of Make My Meal Meatless - have
              a poke around, just don't expect everything to work yet!
              <br />
              <br />
              Follow us on Instagram or sign up ✉️ to follow along with updates
              from the team and get notified when we officially launch the site.
              We can't wait for you to join us.
            </div>
          </div>
          <br />
          <br />
          <div className="buttonFlexCenter">
            <Button
              variant="warning"
              className="goButton accentButton"
              href="https://www.instagram.com/makemymealmeatless/?hl=en-gb"
              target="blank">
              Follow on Instagram <i class="bi bi-instagram"></i>
            </Button>
            <Button
              variant="warning"
              className="goButton accentButton"
              href="http://eepurl.com/hYH-Nv"
              target="blank">
              Get notified <i class="bi bi-envelope"></i>
            </Button>
            <Button
              variant="warning"
              className="goButton accentButton"
              href="/main">
              Visit the Site <i class="bi bi-arrow-right-circle"></i>
            </Button>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}
