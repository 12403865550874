import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const SubscribePage = () => {
  const [emailValue, setEmailValue] = useState("");
  const [fNameValue, setFNameValue] = useState("");

  return (
    <Form
      action="https://MakeMyMealMeatless.us14.list-manage.com/subscribe/post"
      method="POST"
      noValidate>
      <input type="hidden" name="u" value="852b61b210a27854b25cc8e87" />
      <input type="hidden" name="id" value="f5b32ec57e" />
      <Form.Group htmlfor="MERGE0">
        <Form.Control
          type="email"
          name="EMAIL"
          id="MERGE0"
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
          autoCapitalize="off"
          autoCorrect="off"
          className="footerFormInput"
          size="sm"
          placeholder="Enter email"
        />
      </Form.Group>
      <Form.Group htmlfor="MERGE1">
        <Form.Control
          type="text"
          name="FNAME"
          id="MERGE0"
          value={fNameValue}
          onChange={(e) => setFNameValue(e.target.value)}
          className="footerFormInput"
          autoCapitalize="off"
          autoCorrect="off"
          size="sm"
          placeholder="Enter first name"
        />
      </Form.Group>
      <Button
        className="mt-3 footerFormButton"
        variant="light"
        id="mc-embedded-subscribe"
        value="Subscribe"
        name="subscribe"
        type="submit">
        Submit
      </Button>
      <div
        style={{ position: "absolute", left: "-5000px" }}
        aria-hidden="true"
        aria-label="Please leave the following three fields empty">
        <label htmlFor="b_name">Name: </label>
        <input
          type="text"
          name="b_name"
          tabIndex="-1"
          value=""
          placeholder="Freddie"
          id="b_name"
        />

        <label htmlFor="b_email">Email: </label>
        <input
          type="email"
          name="b_email"
          tabIndex="-1"
          value=""
          placeholder="youremail@gmail.com"
          id="b_email"
        />

        <label htmlFor="b_comment">Comment: </label>
        <textarea
          name="b_comment"
          tabIndex="-1"
          placeholder="Please comment"
          id="b_comment"></textarea>
      </div>
    </Form>
  );
};

export default SubscribePage;
