import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { firebaseAuth } from "../firebase";
import SignInForm from "../Components/AuthSignIn";
import {
  ProductsTable,
  MeatTable,
  ShopTable,
  BrandTable,
} from "../Components/ProductsTable";

export default function AdminPage() {
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if user is authenticated
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      // Unsubscribe from onAuthStateChanged listener when component unmounts
      unsubscribe();
    };
  }, []);

  const handleLogout = () => {
    firebaseAuth.signOut().then(() => {
      setUser(null);
      history.push("/main");
    });
  };

  return (
    <>
      <h1 className="titleText centeredText">Admin Page</h1>
      {user ? (
        <div>
          <p className="infoText">You are logged in.</p>
          <Button onClick={handleLogout}>Logout</Button>
          <br />
          <br />
          <br />
          <MeatTable />
          <BrandTable />
          <ShopTable />
          <ProductsTable />
        </div>
      ) : (
        <SignInForm />
      )}
    </>
  );
}
