import meatData from "./meatData.json";
import alternativeFoodData from "./substituteData.json";
import shopData from "./shopData.json";
import dietaryData from "./dietaryData.json";
import storageData from "./storageData.json";
import brandData from "./brandData.json";

const allData = {
  meatData: meatData,
  alternativeFoodData: alternativeFoodData,
  shopData: shopData,
  dietaryData: dietaryData,
  storageData: storageData,
  brandData: brandData,
  includesItem: function (dataToSearch, propertyToLookIn, searchFor) {
    let searchResults = [];
    this[dataToSearch].forEach((el) => {
      let found = "nothing found";
      if (el[propertyToLookIn] !== undefined) {
        if (typeof el[propertyToLookIn] === "string") {
          found = el[propertyToLookIn].includes(searchFor);
        } else if (Array.isArray(el[propertyToLookIn])) {
          found = el[propertyToLookIn].includes(searchFor);
        }
      }
      if (found) {
        searchResults.push(el);
      }
    });
    return searchResults;
  },
};

export default allData;
